import React, { useState, useEffect, useRef, useCallback } from 'react';
import '../styles/Configuration.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrash,
  faPlus,
  faGear,
  faCompass,
  faWalkieTalkie,
  faSave,
  faInfoCircle,
  faMailBulk,
  faHandPaper,
  faUserPlus,
  faTools,
  faPaperPlane,
  faEdit,
  faAdd,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { cosmosClient } from '../utils/eventstorage';
import { PLIX_OVERWATCH_ACCOUNT } from '../utils/utilsEvents';
import { useDeviceFilter } from '../contexts/DeviceFilterContext';
import { updateDeviceConfig } from '../utils/deviceConfig';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import ReportSubscribersTable from './ReportSubscribersTable';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { sendReport } from '../api/beaverApi';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { fetchPersonnelAccounts, deletePersonnelAccount, updatePersonnelDevices } from '../api/beaverApi';

// Material-UI components
import {
  Autocomplete,
  TextField,
  Button,
  Modal,
  Box,
  Tooltip,
  Radio,
  RadioGroup,
  FormControl,
  Select,
  MenuItem,
  Chip,
  Switch,
  Typography,
  FormLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  CircularProgress,
  IconButton,
  Snackbar,
  useMediaQuery,
  DialogActions,
  InputLabel,
} from '@mui/material';

// Material-UI icons
import {
  Info as InfoIcon,
  Warning as WarningIcon,
  RestartAlt as RestartAltIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  Check as CheckIcon,
  Close as CloseIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Settings as SettingsIcon,
  Send as SendIcon, // Add this import
  Construction as ConstructionIcon,
  Add as AddIcon,
} from '@mui/icons-material';

// Material-UI styled components
import { styled } from '@mui/material/styles';
import MuiAlert from '@mui/material/Alert';
import { createPersonnelAccount } from '../api/beaverApi';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
dayjs.extend(utc);
dayjs.extend(timezone);

const RedSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#ff0000',
    '&:hover': {
      backgroundColor: 'rgba(255, 0, 0, 0.08)',
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#ff0000',
  },
}));

// Add this custom styled Tooltip
const StyledTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    '& .MuiTooltip-tooltip': {
      backgroundColor: 'white',
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      fontSize: 14,
      border: '1px solid #dadde9',
      maxWidth: 320,
      padding: theme.spacing(2),
      '& b': {
        fontWeight: 600,
      },
    },
  })
);

dayjs.extend(utc);
dayjs.extend(timezone);

// Add these tooltip contents near the top of the file with other constants
const DEVICE_FEEDBACK_INFO = `
<b>Device Feedback Modes:</b><br/><br/>
<b>Balanced:</b> Audio feedback enabled for all events except for escalation recordings.<br/>
<b>Deterrent:</b> Audio feedback enabled for all events, with periodic beeps and light flashes during recording.<br/>
<b>Stealth:</b> Audio feedback disabled.
`;

const RECORDING_ACTIVATION_INFO = `
<b>Recording Activation Methods:</b><br/><br/>
<b>Shift Based:</b> Recording starts/stops automatically based on shift status.<br/>
<b>Flexible Control:</b> Recording can be triggered manually or through smart triggers during shift.
`;
const SMART_TRIGGER_INFO = `
<b>All Triggers:</b> Record video hands-free when a situation is verbally escalating or a trigger phrase is used. <br/>
<b>Trigger Phrases:</b> Record video hands-free only when a trigger phrase is used. <br/>
<b>Never:</b> Never record video hands-free.
`;
const Configuration = ({ userId, retentionPeriodDays, featureMode, isOverwatch, userRole }) => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const { devices: initialDevices, selectedOrg } = useDeviceFilter();
  const getEffectiveUserId = () => (isOverwatch ? selectedOrg : userId);
  const [triggers, setTriggers] = useState([]);
  const [errors, setErrors] = useState([]);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [liveViewPolicy, setLiveViewPolicy] = useState('During Shift');
  const [recordingPolicy, setRecordingPolicy] = useState('User');
  const [consentModalOpen, setConsentModalOpen] = useState(false);
  const [pendingSave, setPendingSave] = useState(false);
  const [initialPhoneNumbers, setInitialPhoneNumbers] = useState([]);
  const [emailAddresses, setEmailAddresses] = useState([]);
  const [newEmail, setNewEmail] = useState('');
  const [reportFrequency, setReportFrequency] = useState('weekly');
  const [reportHour, setReportHour] = useState('5');
  const [reportMinute, setReportMinute] = useState('0');
  const [reportAmPm, setReportAmPm] = useState('PM');
  const [timezone, setTimezone] = useState('');
  const [devices, setDevices] = useState([]);
  const [pendingDeviceChanges, setPendingDeviceChanges] = useState({});
  const [orderBy, setOrderBy] = useState('assignedTo');
  const [order, setOrder] = useState('asc');
  const [globalDeviceMode, setGlobalDeviceMode] = useState('normal');
  const [globalRecordingPolicy, setGlobalRecordingPolicy] = useState('User');
  const [applyGlobalDeviceMode, setApplyGlobalDeviceMode] = useState(false);
  const [applyGlobalRecordingPolicy, setApplyGlobalRecordingPolicy] = useState(false);
  const [restartingDevices, setRestartingDevices] = useState(() => {
    const savedRestarts = localStorage.getItem('restartingDevices');
    if (savedRestarts) {
      try {
        const parsed = JSON.parse(savedRestarts);
        const now = Date.now();
        // Filter out any expired cooldowns
        return Object.entries(parsed).reduce((acc, [deviceId, timestamp]) => {
          if (timestamp === 'loading' || now - timestamp < 900000) {
            acc[deviceId] = timestamp;
          }
          return acc;
        }, {});
      } catch (error) {
        console.error('Error parsing restartingDevices from localStorage:', error);
        return {};
      }
    }
    return {};
  });
  const [editingDevice, setEditingDevice] = useState(null);
  const [editValues, setEditValues] = useState({});
  const [pendingNameChanges, setPendingNameChanges] = useState({});
  const [reportSubscribers, setReportSubscribers] = useState([]);
  const showModeColumn = userId !== 'auth0|669fed96e6d5fdc532aedf04';
  const [activeSection, setActiveSection] = useState('');
  const [isContentLoaded, setIsContentLoaded] = useState(false);
  const sectionRefs = {
    Devices: useRef(null),
    'Trigger Phrases': useRef(null),
    Reports: useRef(null),
    Personnel: useRef(null),
  };
  const [allDevicesValues, setAllDevicesValues] = useState({
    deviceMode: '',
    buttonType: '',
    recordingPolicy: '',
    escalationMode: '',
  });
  const [currentDeviceValues, setCurrentDeviceValues] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [showAllDevices, setShowAllDevices] = useState(false);
  const [stopPhrase, setStopPhrase] = useState('');
  const [stopPhraseError, setStopPhraseError] = useState('');
  const [showAddPersonnelDialog, setShowAddPersonnelDialog] = useState(false);
  const [newPersonnelName, setNewPersonnelName] = useState('');
  const [newPersonnelEmail, setNewPersonnelEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [personnelAccounts, setPersonnelAccounts] = useState([]);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [accountToDelete, setAccountToDelete] = useState(null);
  const [editPersonnelDialogOpen, setEditPersonnelDialogOpen] = useState(false);
  const [editingPersonnel, setEditingPersonnel] = useState(null);
  const [editPersonnelName, setEditPersonnelName] = useState('');
  const [editSelectedDevices, setEditSelectedDevices] = useState([]);
  const [configureAllDialogOpen, setConfigureAllDialogOpen] = useState(false);
  const [bulkConfig, setBulkConfig] = useState({
    recordingMode: 'automatic',
    manualTrigger: '',
    keywordTriggers: false,
    escalationTriggers: false,
    deviceMode: 'normal', // Add deviceMode for audio feedback
    deviceName: '', // This won't be used in bulk but keeping state consistent
  });
  const [configureDeviceDialogOpen, setConfigureDeviceDialogOpen] = useState(false);
  const [showAddReportDialog, setShowAddReportDialog] = useState(false);
  const [editingReport, setEditingReport] = useState(null);
  const [newReport, setNewReport] = useState({
    emailAddress: '',
    frequency: 'weekly',
    timeOfDay: '16:00', // Default to 4:00 PM
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    reportType: 'site',
  });

  // Add this state to track if we're adding a new trigger
  const [isAddingTrigger, setIsAddingTrigger] = useState(false);

  // Add new state variables
  const [newPersonnelFirstName, setNewPersonnelFirstName] = useState('');
  const [newPersonnelLastName, setNewPersonnelLastName] = useState('');
  const [editPersonnelFirstName, setEditPersonnelFirstName] = useState('');
  const [editPersonnelLastName, setEditPersonnelLastName] = useState('');

  const location = useLocation();
  const navigate = useNavigate();

  const isGuard = userRole === 'guard';

  useEffect(() => {
    // Simulate content loading
    const timer = setTimeout(() => {
      setIsContentLoaded(true);
    }, 1000); // Adjust this time based on your actual content loading time

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (isContentLoaded) {
      const hash = decodeURIComponent(location.hash.replace('#', ''));
      if (hash && sectionRefs[hash]) {
        sectionRefs[hash].current.scrollIntoView({ behavior: 'smooth' });
        setActiveSection(hash);
      } else {
        setActiveSection('Devices'); // Default to first section if no hash
      }
    }
  }, [location, isContentLoaded]);

  const handleTableOfContentsClick = (section) => {
    if (sectionRefs[section] && sectionRefs[section].current) {
      sectionRefs[section].current.scrollIntoView({ behavior: 'smooth' });
      setActiveSection(section);
      navigate(`/settings#${encodeURIComponent(section)}`, { replace: true });
    }
  };
  useEffect(() => {
    const getTimezone = () => {
      const formatter = new Intl.DateTimeFormat();
      const timezone = formatter.resolvedOptions().timeZone;
      console.log('timezone', timezone);
      setTimezone(timezone);
    };

    getTimezone();
  }, []);
  useEffect(() => {
    const loadPersonnelAccounts = async () => {
      try {
        const accounts = await fetchPersonnelAccounts(getEffectiveUserId());
        setPersonnelAccounts(accounts || []);
      } catch (error) {
        console.error('Error loading personnel accounts:', error);
        setSnackbarMessage('Failed to load personnel accounts');
        setSnackbarOpen(true);
      }
    };

    loadPersonnelAccounts();
  }, []); // Add any dependencies if needed

  const handleEditPersonnel = (account) => {
    setEditingPersonnel(account);

    // Split the stored name into first and last name
    const [firstName, lastName] = (account.assignedTo || '').split('_');
    setEditPersonnelFirstName(firstName || '');
    setEditPersonnelLastName(lastName || '');

    setEditSelectedDevices(account.deviceIds);
    setEditPersonnelDialogOpen(true);
  };

  const handleDeletePersonnel = (account) => {
    setAccountToDelete(account);
    setDeleteConfirmOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      // console.log('Deleting personnel account with ID:', accountToDelete.orgUserId);
      await deletePersonnelAccount(accountToDelete.orgUserId);

      // Update the local state to remove the deleted account
      setPersonnelAccounts((prevAccounts) =>
        prevAccounts.filter((account) => account.orgUserId !== accountToDelete.orgUserId)
      );

      setSnackbarMessage('Personnel account deleted successfully');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error deleting personnel account:', error);
      setSnackbarMessage(error.message || 'Failed to delete personnel account');
      setSnackbarOpen(true);
    } finally {
      setDeleteConfirmOpen(false);
      setAccountToDelete(null);
    }
  };

  const inputStyle = {
    '& .MuiOutlinedInput-root': {
      borderRadius: '4px',
    },
  };

  const chipStyle = {
    '& .MuiOutlinedInput-root': {
      borderRadius: '4px',
    },
  };

  const timeBoxStyle = {
    display: 'flex',
    border: '1px solid #ccc',
    borderRadius: '9999px', // This creates a pill shape
    overflow: 'hidden',
    '& .MuiOutlinedInput-root': {
      borderRadius: 0,
    },
    '& .MuiSelect-select': {
      paddingRight: '24px',
    },
    '& .MuiSelect-icon': {
      right: '0',
    },
  };

  const timeSelectStyle = {
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const effectiveUserId = getEffectiveUserId();
        const devicesContainer = cosmosClient.database('Devices').container('Devices');
        const { resources: fetchedDevices } = isGuard
          ? await devicesContainer.items
              .query({
                query: 'SELECT * FROM c WHERE c.deviceId = @deviceId',
                parameters: [{ name: '@deviceId', value: initialDevices[0].deviceId }],
              })
              .fetchAll()
          : await devicesContainer.items
              .query({
                query: 'SELECT * FROM c WHERE c.userId = @userId',
                parameters: [{ name: '@userId', value: effectiveUserId }],
              })
              .fetchAll();

        const fetchedDevicesWithDefaults = fetchedDevices.map((device) => ({
          ...device,
          escalationMode: device.escalationMode || 'on',
          buttonType: device.buttonType || 'MULTI_TOUCH',
        }));
        setDevices(fetchedDevicesWithDefaults);

        updateAllDevicesValues(
          fetchedDevicesWithDefaults,
          'deviceMode',
          fetchedDevicesWithDefaults[0].deviceMode || 'balanced',
          null
        );
        updateAllDevicesValues(
          fetchedDevicesWithDefaults,
          'buttonType',
          fetchedDevicesWithDefaults[0].buttonType || 'MULTI_TOUCH',
          null
        );
        updateAllDevicesValues(
          fetchedDevicesWithDefaults,
          'recordingPolicy',
          fetchedDevicesWithDefaults[0].recordingPolicy || 'User',
          null
        );
        updateAllDevicesValues(
          fetchedDevicesWithDefaults,
          'escalationMode',
          fetchedDevicesWithDefaults[0].escalationMode || 'on',
          null
        );

        if (fetchedDevices.length > 0) {
          const firstDevice = fetchedDevices[0];
          const keywords = (firstDevice.activationKeyword || '').split(',').filter(Boolean);
          const phoneNumbers = (firstDevice.notificationPhoneNumber || '').split(',');

          const initialTriggers = keywords.map((keyword, index) => ({
            condition: keyword.trim(),
            actions: {
              startRecording: true,
              generateEscalation: true,
              sendWhatsApp: phoneNumbers[index] ? true : false,
            },
            phoneNumber: phoneNumbers[index]
              ? `(${phoneNumbers[index].slice(0, 3)}) ${phoneNumbers[index].slice(3, 6)}-${phoneNumbers[index].slice(6)}`
              : '',
          }));

          setTriggers(initialTriggers);
          setErrors(initialTriggers.map(() => ({ condition: '', actions: {}, phoneNumber: '' })));
          setLiveViewPolicy(firstDevice.liveViewPolicy || 'During Shift');
          setInitialPhoneNumbers(phoneNumbers);
          setRecordingPolicy(firstDevice.recordingPolicy || 'User');
        }

        // Fetch organization
        const orgContainer = cosmosClient.database('Organizations').container('Organizations');
        const { resources: orgs } = await orgContainer.items
          .query({
            query: 'SELECT * FROM c WHERE c.orgUserId = @userId',
            parameters: [{ name: '@userId', value: effectiveUserId }],
          })
          .fetchAll();

        if (orgs.length > 0) {
          const org = orgs[0];
          // Initialize report subscribers
          console.log('org.reportSubscribers: ', org.reportSubscribers, ' org: ', org);
          if (org.reportSubscribers) {
            setReportSubscribers(
              org.reportSubscribers.map((subscriber) => ({
                ...subscriber,
                timeOfDay: subscriber.timeOfDay || dayjs().set('hour', 16).set('minute', 0).utc().format('HH:mm'), // Default to 4 PM UTC if not set
              }))
            );
            setStopPhrase(org.stopPhrase || 'Stop Recording');
          } else {
            setReportSubscribers([]);
          }

          const emailReportPolicy = org.emailReportPolicy || {};
          setEmailAddresses(emailReportPolicy.addresses || []);
          setReportFrequency(emailReportPolicy.frequency || 'weekly');
          setTimezone(emailReportPolicy.timezone || '');

          if (emailReportPolicy.timeOfDay) {
            const [hour, minute] = emailReportPolicy.timeOfDay.split(':');
            if (parseInt(hour) > 12) {
              setReportHour(parseInt(hour) - 12);
              setReportAmPm('PM');
            } else {
              if (parseInt(hour) == 0) {
                setReportHour(12);
              } else {
                setReportHour(parseInt(hour));
              }
              setReportAmPm('AM');
            }
            setReportMinute(parseInt(minute));
          }
        }
      } catch (error) {
        console.error('Failed to fetch initial data:', error);
      }
    };

    fetchInitialData();
  }, [userId, selectedOrg]);

  // Modify handleAddTrigger
  const handleAddTrigger = () => {
    if (editingTriggerIndex !== null || isAddingTrigger) return; // Prevent adding if already editing

    setIsAddingTrigger(true);
    setTriggers([
      ...triggers,
      {
        condition: '',
        actions: { startRecording: true, generateEscalation: true, sendWhatsApp: false },
        phoneNumber: '',
      },
    ]);
    setErrors([...errors, { condition: '', actions: {}, phoneNumber: '' }]);
    setEditingTriggerIndex(triggers.length);
    setEditingTrigger('');
  };

  const handleTriggerChange = (index, field, value) => {
    const newTriggers = [...triggers];
    newTriggers[index] = { ...newTriggers[index], [field]: value };
    setTriggers(newTriggers);
    // Remove the setUnsavedChanges call since we'll save directly
  };

  // Add new state for editing
  const [editingTriggerIndex, setEditingTriggerIndex] = useState(null);
  const [editingTrigger, setEditingTrigger] = useState('');

  // Add new handlers for inline editing
  const handleStartEdit = (index, value) => {
    setEditingTriggerIndex(index);
    setEditingTrigger(value || '');
  };

  // Modify handleCancelEdit
  const handleCancelEdit = () => {
    if (isAddingTrigger) {
      // Remove the last trigger if we were adding a new one
      setTriggers(triggers.slice(0, -1));
      setErrors(errors.slice(0, -1));
      setIsAddingTrigger(false);
    }
    setEditingTriggerIndex(null);
    setEditingTrigger('');
  };

  // Modify handleSaveTriggerPhrase to only save activationKeyword
  const handleSaveTriggerPhrase = async (index) => {
    try {
      const newTriggers = [...triggers];
      newTriggers[index] = { ...newTriggers[index], condition: editingTrigger };

      // Update organization with just the activation keywords
      const effectiveUserId = getEffectiveUserId();
      const orgContainer = cosmosClient.database('Organizations').container('Organizations');

      const { resources: orgs } = await orgContainer.items
        .query({
          query: 'SELECT * FROM c WHERE c.orgUserId = @userId',
          parameters: [{ name: '@userId', value: effectiveUserId }],
        })
        .fetchAll();

      if (orgs.length === 0) {
        throw new Error('Organization not found');
      }

      const org = orgs[0];
      const activationKeywords = newTriggers
        .map((trigger) => trigger.condition.trim())
        .filter(Boolean)
        .join(',');

      const updatedOrg = {
        ...org,
        activationKeyword: activationKeywords || undefined,
      };

      await orgContainer.item(org.id, org.orgName).replace(updatedOrg);

      // Update devices with the new activation keywords
      await updateDeviceKeywords(activationKeywords);

      // Update local state
      setTriggers(newTriggers);
      setEditingTriggerIndex(null);
      setEditingTrigger('');
      setIsAddingTrigger(false);

      setSnackbarMessage('Trigger phrase saved successfully');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Failed to save trigger phrase:', error);
      setSnackbarMessage('Failed to save trigger phrase');
      setSnackbarOpen(true);
    }
  };

  // Similar handlers for stop phrase
  const [editingStopPhrase, setEditingStopPhrase] = useState(false);
  const [editingStopPhraseValue, setEditingStopPhraseValue] = useState('');

  const handleStartEditStopPhrase = () => {
    setEditingStopPhrase(true);
    setEditingStopPhraseValue(stopPhrase);
  };

  const handleCancelEditStopPhrase = () => {
    setEditingStopPhrase(false);
    setEditingStopPhraseValue('');
  };

  const handleSaveStopPhrase = async () => {
    try {
      const effectiveUserId = getEffectiveUserId();
      const orgContainer = cosmosClient.database('Organizations').container('Organizations');

      const { resources: orgs } = await orgContainer.items
        .query({
          query: 'SELECT * FROM c WHERE c.orgUserId = @userId',
          parameters: [{ name: '@userId', value: effectiveUserId }],
        })
        .fetchAll();

      if (orgs.length === 0) {
        throw new Error('Organization not found');
      }

      const org = orgs[0];
      const updatedOrg = {
        ...org,
        stopPhrase: editingStopPhraseValue,
      };

      await orgContainer.item(org.id, org.orgName).replace(updatedOrg);

      // Update local state
      setStopPhrase(editingStopPhraseValue);
      setEditingStopPhrase(false);
      setEditingStopPhraseValue('');

      setSnackbarMessage('Stop phrase saved successfully');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Failed to save stop phrase:', error);
      setSnackbarMessage('Failed to save stop phrase');
      setSnackbarOpen(true);
    }
  };

  const handleStopPhraseChange = (event, newValue) => {
    const value = newValue || '';
    const error = validateField('stopPhrase', value);
    setStopPhrase(value);
    setStopPhraseError(error);
    setUnsavedChanges(true);
  };

  const validateField = (field, value, trigger) => {
    switch (field) {
      case 'condition':
        if (!value || !value.trim()) return 'Trigger phrase is required';
        if (value.trim().length < 4) return 'Trigger phrase must be at least 4 characters long';
        if (value.trim().split(' ').length > 2 || value.length > 50) return 'Must be at most 2 words and 50 characters';
        if (/[^\w\s]/.test(value)) return 'Punctuation is not allowed in the trigger phrase';
        if (value.includes('Wolverines')) return 'Invalid sports team to support';
        // New check for stop phrase
        if (
          stopPhrase &&
          (value.toLowerCase().includes(stopPhrase.toLowerCase()) ||
            stopPhrase.toLowerCase().includes(value.toLowerCase()))
        ) {
          return 'Trigger phrase cannot contain the stop phrase';
        }
        break;
      case 'stopPhrase':
        if (!value || !value.trim()) return 'Stop phrase is required';
        if (value.trim().length < 4) return 'Stop phrase must be at least 4 characters long';
        if (value.trim().split(' ').length > 3 || value.length > 50) return 'Must be at most 3 words and 50 characters';
        if (/[^\w\s]/.test(value)) return 'Punctuation is not allowed in the stop phrase';
        // Check if any trigger phrase contains the stop phrase
        if (
          triggers.some(
            (trigger) =>
              trigger.condition.toLowerCase().includes(value.toLowerCase()) ||
              value.toLowerCase().includes(trigger.condition.toLowerCase())
          )
        ) {
          return 'Stop phrase cannot be contained in any trigger phrase';
        }
        break;
      case 'actions':
        if (
          !value ||
          (typeof value === 'object' && !value.startRecording && !value.generateEscalation && !value.sendWhatsApp)
        )
          return 'At least one action is required';
        break;
      case 'phoneNumber':
        if (trigger && trigger.actions.sendWhatsApp && (!value || !value.trim())) return 'Phone number is required';
        const phonePattern = /^\(\d{3}\) \d{3}-\d{4}$/;
        if (value && !phonePattern.test(value)) return 'Valid phone number required.';
        break;
      default:
        break;
    }
    return '';
  };

  const handleRemoveTrigger = async (index) => {
    try {
      // Remove trigger from local state
      const newTriggers = triggers.filter((_, i) => i !== index);
      setTriggers(newTriggers);
      const newErrors = errors.filter((_, i) => i !== index);
      setErrors(newErrors);

      // Get comma-separated keywords string from remaining triggers
      const activationKeywords = newTriggers
        .map((trigger) => trigger.condition.trim())
        .filter(Boolean)
        .join(',');

      // Update organization
      const effectiveUserId = getEffectiveUserId();
      const orgContainer = cosmosClient.database('Organizations').container('Organizations');
      const { resources: orgs } = await orgContainer.items
        .query({
          query: 'SELECT * FROM c WHERE c.orgUserId = @userId',
          parameters: [{ name: '@userId', value: effectiveUserId }],
        })
        .fetchAll();

      if (orgs.length > 0) {
        const org = orgs[0];
        const updatedOrg = {
          ...org,
        };
        
        // Only include activationKeyword if there are remaining keywords
        if (activationKeywords) {
          updatedOrg.activationKeyword = activationKeywords;
        } else {
          delete updatedOrg.activationKeyword;
        }
        
        await orgContainer.item(org.id, org.orgName).replace(updatedOrg);
      }

      // Update devices
      const devicesContainer = cosmosClient.database('Devices').container('Devices');
      const { resources: devices } = await devicesContainer.items
        .query({
          query: 'SELECT * FROM c WHERE c.userId = @userId',
          parameters: [{ name: '@userId', value: effectiveUserId }],
        })
        .fetchAll();

      // Update each device
      const updateDevicePromises = devices.map(async (device) => {
        const updatedDevice = {
          ...device,
        };
        
        // Only include activationKeyword if there are remaining keywords
        if (activationKeywords) {
          updatedDevice.activationKeyword = activationKeywords;
        } else {
          delete updatedDevice.activationKeyword;
        }
        
        await devicesContainer.item(device.id, device.deviceId).replace(updatedDevice);
      });

      await Promise.all(updateDevicePromises);

      setUnsavedChanges(true);
      setSnackbarMessage('Trigger phrase removed successfully');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Failed to remove trigger phrase:', error);
      setSnackbarMessage('Failed to remove trigger phrase');
      setSnackbarOpen(true);
    }
  };

  const updateReportSubscribers = async (effectiveUserId) => {
    const orgContainer = cosmosClient.database('Organizations').container('Organizations');

    try {
      const { resources: orgs } = await orgContainer.items
        .query({
          query: 'SELECT * FROM c WHERE c.orgUserId = @userId',
          parameters: [{ name: '@userId', value: effectiveUserId }],
        })
        .fetchAll();

      if (orgs.length === 0) {
        throw new Error('Organization not found');
      }

      const org = orgs[0];

      const updatedOrg = {
        ...org,
        reportSubscribers: reportSubscribers,
      };

      await orgContainer.item(org.id, org.orgName).replace(updatedOrg);
      console.log('Report subscribers updated successfully');
    } catch (error) {
      console.error('Failed to update report subscribers:', error);
      throw error;
    }
  };

  // Update the updateDeviceKeywords function to take activationKeywords directly
  const updateDeviceKeywords = async (activationKeywords) => {
    const devicesContainer = cosmosClient.database('Devices').container('Devices');
    const orgContainer = cosmosClient.database('Organizations').container('Organizations');

    try {
      // Fetch devices
      const effectiveUserId = getEffectiveUserId();
      const { resources: devices } = await devicesContainer.items
        .query({
          query: 'SELECT * FROM c WHERE c.userId = @userId',
          parameters: [{ name: '@userId', value: effectiveUserId }],
        })
        .fetchAll();

      // Update devices
      const updateDevicePromises = devices.map(async (device) => {
        const updatedDevice = {
          ...device,
          activationKeyword: activationKeywords || undefined,
          stopPhrase: stopPhrase,
        };

        await devicesContainer.item(device.id, device.deviceId).replace(updatedDevice);
      });

      await Promise.all(updateDevicePromises);
    } catch (error) {
      console.error('Failed to update device keywords:', error);
      throw error;
    }
  };

  const handleSaveTrigger = async () => {
    const newPhoneNumbers = triggers
      .filter((trigger) => trigger.actions.sendWhatsApp)
      .map((trigger) => trigger.phoneNumber.replace(/[^\d]/g, ''));

    const hasNewPhoneNumber = newPhoneNumbers.some((number) => !initialPhoneNumbers.includes(number));

    if (!liveViewPolicy || !recordingPolicy) {
      setErrors((prevErrors) =>
        prevErrors.map((error) => ({
          ...error,
          liveViewPolicy: !liveViewPolicy ? 'Live View policy is required' : error.liveViewPolicy,
          recordingPolicy: !recordingPolicy ? 'Recording policy is required' : error.recordingPolicy,
        }))
      );
      return;
    }

    const newErrors = triggers.map((trigger) => ({
      condition: validateField('condition', trigger.condition),
      actions: {
        startRecording: validateField('actions', trigger.actions.startRecording ? 'startRecording' : ''),
        generateEscalation: validateField('actions', trigger.actions.generateEscalation ? 'generateEscalation' : ''),
        sendWhatsApp: validateField('actions', trigger.actions.sendWhatsApp ? 'sendWhatsApp' : ''),
      },
      phoneNumber: validateField('phoneNumber', trigger.phoneNumber, trigger),
    }));

    setErrors(newErrors);

    const hasErrors = newErrors.some(
      (error, index) =>
        error.condition ||
        error.actions.startRecording ||
        error.actions.generateEscalation ||
        (triggers[index].actions.sendWhatsApp && error.phoneNumber)
    );

    if (hasErrors) {
      return;
    }

    if (hasNewPhoneNumber) {
      setConsentModalOpen(true);
      setPendingSave(true);
      return;
    }

    try {
      const effectiveUserId = getEffectiveUserId();
      await updateDeviceKeywords(triggers);
      await updateEmailReportPolicy(effectiveUserId);
      await updateReportSubscribers(effectiveUserId);

      let changedDevices = [];
      if (Object.keys(pendingDeviceChanges).length > 0 || Object.keys(pendingNameChanges).length > 0) {
        changedDevices = await updateDevices(effectiveUserId);
      }

      // After all Cosmos DB updates are complete, make the API call only for changed devices
      for (const deviceId of changedDevices) {
        try {
          const deviceChanges = pendingDeviceChanges[deviceId];
          const updateType = deviceChanges.buttonType !== undefined ? 'fetch and update' : 'fetch config';

          const response = await fetch('https://utils.plix.ai/v1/updateconfig', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              deviceId: deviceId,
              updateType: updateType,
            }),
          });

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const result = await response.json();
          console.log(`Configuration update triggered for device ${deviceId}:`, result);
        } catch (error) {
          console.error(`Failed to trigger configuration update for device ${deviceId}:`, error);
        }
      }

      setUnsavedChanges(false);

      // Set save success message
      setSaveSuccess(true);
      // Hide success message after 3 seconds
      setTimeout(() => setSaveSuccess(false), 3000);
    } catch (error) {
      console.error('Failed to save configuration:', error);
      // You might want to show an error message to the user here
    }
  };

  const completeSave = async () => {
    if (
      triggers.every(
        (trigger) =>
          trigger.condition &&
          trigger.actions.startRecording &&
          trigger.actions.generateEscalation &&
          (!trigger.actions.sendWhatsApp || (trigger.actions.sendWhatsApp && trigger.phoneNumber))
      )
    ) {
      try {
        await updateDeviceKeywords(triggers);
        setUnsavedChanges(false);
        // Set save success message
        setSaveSuccess(true);
        // Hide success message after 3 seconds
        setTimeout(() => setSaveSuccess(false), 3000);
      } catch (error) {
        console.error('Failed to save configuration:', error);
        // You might want to show an error message to the user here
      }
    } else {
      const newErrors = triggers.map((trigger) => ({
        condition: validateField('condition', trigger.condition),
        actions: {
          startRecording: validateField('actions', trigger.actions.startRecording ? 'startRecording' : ''),
          generateEscalation: validateField('actions', trigger.actions.generateEscalation ? 'generateEscalation' : ''),
          sendWhatsApp: validateField('actions', trigger.actions.sendWhatsApp ? 'sendWhatsApp' : ''),
        },
        phoneNumber: validateField('phoneNumber', trigger.phoneNumber, trigger),
      }));
      setErrors(newErrors);
    }
    setPendingSave(false);
  };

  const handleConsentAgree = () => {
    setConsentModalOpen(false);
    completeSave();
  };

  const handleConsentCancel = () => {
    setConsentModalOpen(false);
    setPendingSave(false);
  };

  const handleAddEmail = () => {
    if (newEmail && !emailAddresses.includes(newEmail)) {
      setEmailAddresses([...emailAddresses, newEmail]);
      setNewEmail('');
      setUnsavedChanges(true);
    }
  };

  const handleRemoveEmail = (emailToRemove) => {
    setEmailAddresses(emailAddresses.filter((email) => email !== emailToRemove));
    setUnsavedChanges(true);
  };

  const handleFrequencyChange = (event) => {
    setReportFrequency(event.target.value);
    setUnsavedChanges(true);
  };

  const handleHourChange = (event) => {
    setReportHour(event.target.value);
    setUnsavedChanges(true);
  };

  const handleMinuteChange = (event) => {
    setReportMinute(event.target.value);
    setUnsavedChanges(true);
  };

  const handleAmPmChange = (event) => {
    setReportAmPm(event.target.value);
    setUnsavedChanges(true);
  };

  const handleTimezoneChange = (event) => {
    setTimezone(event.target.value);
    setUnsavedChanges(true);
  };

  const updateEmailReportPolicy = async (effectiveUserId) => {
    const orgContainer = cosmosClient.database('Organizations').container('Organizations');

    try {
      const { resources: orgs } = await orgContainer.items
        .query({
          query: 'SELECT * FROM c WHERE c.orgUserId = @userId',
          parameters: [{ name: '@userId', value: effectiveUserId }],
        })
        .fetchAll();

      if (orgs.length === 0) {
        throw new Error('Organization not found');
      }

      const org = orgs[0];

      const updatedOrg = {
        ...org,
        reportSubscribers: reportSubscribers,
      };

      await orgContainer.item(org.id, org.orgName).replace(updatedOrg);
      console.log('Report subscribers updated successfully');
    } catch (error) {
      console.error('Failed to update report subscribers:', error);
      throw error;
    }
  };

  useEffect(() => {
    const initialValues = devices.reduce((acc, device) => {
      acc[device.deviceId] = {
        deviceMode: device.deviceMode || 'normal',
        buttonType: device.buttonType || 'MULTI_TOUCH',
        recordingPolicy: device.recordingPolicy || 'User',
        escalationMode: device.escalationMode || 'on',
      };
      return acc;
    }, {});
    console.log('initialValues: ', initialValues);
    setCurrentDeviceValues(initialValues);
  }, [devices]);

  const updateAllDevicesValues = (devicesList, field, newValue, changingDeviceId) => {
    console.log(`[DEBUG-UAV] Updating ${field} with value:`, newValue, 'for device:', changingDeviceId);

    setAllDevicesValues((prev) => {
      const updatedValues = { ...prev };

      if (
        field === 'deviceMode' ||
        field === 'buttonType' ||
        field === 'recordingPolicy' ||
        field === 'escalationMode'
      ) {
        let allSameValue = true;

        console.log(`[DEBUG-UAV] Current devices:`, devicesList);
        console.log(`[DEBUG-UAV] Current pendingDeviceChanges:`, pendingDeviceChanges);

        for (const device of devicesList) {
          let deviceValue;
          if (device.deviceId === changingDeviceId) {
            deviceValue = newValue;
          } else {
            deviceValue = pendingDeviceChanges[device.deviceId]?.[field] || device[field] || prev[field];
          }

          console.log(`[DEBUG-UAV] Checking device ${device.deviceId}, ${field} value:`, deviceValue);

          if (deviceValue !== newValue) {
            allSameValue = false;
            console.log(`[DEBUG-UAV] Found different value for ${field}. Breaking loop.`);
            break;
          }
        }

        updatedValues[field] = allSameValue ? newValue : '';
        console.log(`[DEBUG-UAV] Final value for ${field}:`, updatedValues[field]);
      }

      console.log(`[DEBUG-UAV] Updated allDevicesValues:`, updatedValues);
      return updatedValues;
    });
  };

  const getModeSettings = (mode) => {
    const modeSettings = {
      normal: {
        deviceMode: 'normal',
        screenDim: true,
        recBeep: false,
        TTSEnabled: true,
        hapticEnabled: true,
      },
      stealth: {
        deviceMode: 'stealth',
        screenDim: true,
        recBeep: false,
        TTSEnabled: false,
        hapticEnabled: true,
      },
      deterrent: {
        deviceMode: 'deterrent',
        screenDim: false,
        recBeep: true,
        TTSEnabled: true,
        hapticEnabled: true,
      },
    };
    return modeSettings[mode] || modeSettings.normal;
  };

  const handleAllDevicesChange = (field, value) => {
    setAllDevicesValues((prev) => ({ ...prev, [field]: value }));
    const newChanges = { ...pendingDeviceChanges };
    const newCurrentValues = { ...currentDeviceValues };

    devices.forEach((device) => {
      if (field === 'deviceMode') {
        const modeSettings = getModeSettings(value);
        newChanges[device.deviceId] = {
          ...newChanges[device.deviceId],
          ...modeSettings,
        };
        newCurrentValues[device.deviceId] = {
          ...newCurrentValues[device.deviceId],
          ...modeSettings,
        };
      } else {
        newChanges[device.deviceId] = {
          ...newChanges[device.deviceId],
          [field]: value,
        };
        newCurrentValues[device.deviceId] = {
          ...newCurrentValues[device.deviceId],
          [field]: value,
        };
      }
    });

    setPendingDeviceChanges(newChanges);
    setCurrentDeviceValues(newCurrentValues);
    setUnsavedChanges(true);
  };

  const handleDeviceModeChange = (deviceId, newMode) => {
    const modeSettings = getModeSettings(newMode);

    setPendingDeviceChanges((prev) => ({
      ...prev,
      [deviceId]: { ...prev[deviceId], ...modeSettings },
    }));

    setCurrentDeviceValues((prev) => ({
      ...prev,
      [deviceId]: { ...prev[deviceId], ...modeSettings },
    }));

    updateAllDevicesValues(devices, 'deviceMode', newMode, deviceId);
    setUnsavedChanges(true);
  };

  const handlePolicyChange = (deviceId, policyType, newValue) => {
    setPendingDeviceChanges((prev) => ({
      ...prev,
      [deviceId]: { ...prev[deviceId], [policyType]: newValue },
    }));

    setCurrentDeviceValues((prev) => ({
      ...prev,
      [deviceId]: { ...prev[deviceId], [policyType]: newValue },
    }));

    updateAllDevicesValues(devices, policyType, newValue, deviceId);
    setUnsavedChanges(true);
  };

  const handleSendNow = async (subscriber) => {
    try {
      setSnackbarMessage('Sending report...');
      setSnackbarOpen(true);

      const response = await sendReport(
        getEffectiveUserId(),
        subscriber.emailAddress,
        subscriber.reportType,
        subscriber.frequency
      );

      setSnackbarMessage('Report sent successfully');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error sending report:', error);
      setSnackbarMessage('Failed to send report');
      setSnackbarOpen(true);
    }
  };

  const updateDevices = async (effectiveUserId) => {
    const devicesContainer = cosmosClient.database('Devices').container('Devices');
    const orgContainer = cosmosClient.database('Organizations').container('Organizations');
    const changedDevices = [];

    try {
      // Fetch organization first
      const { resources: orgs } = await orgContainer.items
        .query({
          query: 'SELECT * FROM c WHERE c.orgUserId = @userId',
          parameters: [{ name: '@userId', value: effectiveUserId }],
        })
        .fetchAll();

      if (orgs.length === 0) {
        throw new Error('Organization not found');
      }

      const org = orgs[0];
      const updatedOrgPolicies = {};

      // Check if all devices have the same policies
      const allDevices = devices.filter((device) => device.deviceId !== 'All Devices');
      const policies = ['deviceMode', 'buttonType', 'recordingPolicy', 'escalationMode'];

      policies.forEach((policy) => {
        const firstDeviceValue = pendingDeviceChanges[allDevices[0]?.deviceId]?.[policy] || allDevices[0]?.[policy];
        const allSameValue = allDevices.every(
          (device) => (pendingDeviceChanges[device.deviceId]?.[policy] || device[policy]) === firstDeviceValue
        );

        if (allSameValue && firstDeviceValue) {
          updatedOrgPolicies[policy] = firstDeviceValue;
        }
      });

      // Update organization if there are global policies
      if (Object.keys(updatedOrgPolicies).length > 0) {
        const updatedOrg = {
          ...org,
          ...updatedOrgPolicies,
        };
        await orgContainer.item(org.id, org.orgName).replace(updatedOrg);
        console.log('Organization policies updated:', updatedOrgPolicies);
      }

      // Update individual devices
      const updateDevicePromises = allDevices.map(async (device) => {
        const changes = {
          ...pendingDeviceChanges[device.deviceId],
          ...(pendingNameChanges[device.deviceId] && { assignedTo: pendingNameChanges[device.deviceId] }),
        };

        if (Object.keys(changes).length > 0) {
          const updatedDevice = {
            ...device,
            ...changes,
            recordingPolicy: changes.recordingPolicy || device.recordingPolicy || 'User',
            escalationsEnabled:
              changes.escalationsEnabled !== undefined ? changes.escalationsEnabled : device.escalationsEnabled,
          };

          try {
            await devicesContainer.item(device.id, device.deviceId).replace(updatedDevice);
            console.log(`Device ${device.deviceId} updated successfully`);
            changedDevices.push(device.deviceId);
          } catch (error) {
            console.error(`Failed to update device ${device.deviceId}:`, error);
            throw error;
          }
        }
      });

      await Promise.all(updateDevicePromises);
      return changedDevices;
    } catch (error) {
      console.error('Failed to update devices:', error);
      throw error;
    }
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedDevices = React.useMemo(() => {
    const comparator = (a, b) => {
      const aValue = a[orderBy] || a.deviceName || a.deviceId;
      const bValue = b[orderBy] || b.deviceName || b.deviceId;
      if (order === 'asc') {
        return aValue.localeCompare(bValue);
      } else {
        return bValue.localeCompare(aValue);
      }
    };

    let filteredDevices = devices;
    const sortedRegularDevices = [...filteredDevices].sort(comparator);
    return isGuard ? sortedRegularDevices : [{ deviceId: 'All Devices' }, ...sortedRegularDevices];
  }, [devices, order, orderBy, isGuard, userId]);

  // Add a check at the beginning of the component to prevent rendering if selectedOrg is PLIX_OVERWATCH_ACCOUNT
  if (isOverwatch && selectedOrg === PLIX_OVERWATCH_ACCOUNT) {
    return <div>Configuration is not available for the Overwatch account.</div>;
  }

  const hours = Array.from({ length: 12 }, (_, i) => i + 1);
  const minutes = Array.from({ length: 12 }, (_, i) => i * 5);
  const commonTimezones = [
    'America/New_York',
    'America/Chicago',
    'America/Denver',
    'America/Los_Angeles',
    'Europe/London',
    'Europe/Paris',
    'Asia/Tokyo',
    'Australia/Sydney',
    // Add more as needed
  ];

  const handleGlobalDeviceModeChange = (event) => {
    setGlobalDeviceMode(event.target.value);
    if (applyGlobalDeviceMode) {
      const newChanges = { ...pendingDeviceChanges };
      devices.forEach((device) => {
        newChanges[device.deviceId] = {
          ...newChanges[device.deviceId],
          deviceMode: event.target.value,
        };
      });
      setPendingDeviceChanges(newChanges);
    }
  };

  const handleGlobalRecordingPolicyChange = (event) => {
    setGlobalRecordingPolicy(event.target.value);
    if (applyGlobalRecordingPolicy) {
      const newChanges = { ...pendingDeviceChanges };
      devices.forEach((device) => {
        newChanges[device.deviceId] = {
          ...newChanges[device.deviceId],
          recordingPolicy: event.target.value,
        };
      });
      setPendingDeviceChanges(newChanges);
    }
  };

  const handleApplyGlobalDeviceMode = (event) => {
    setApplyGlobalDeviceMode(event.target.checked);
    if (event.target.checked) {
      const newChanges = { ...pendingDeviceChanges };
      devices.forEach((device) => {
        newChanges[device.deviceId] = {
          ...newChanges[device.deviceId],
          deviceMode: globalDeviceMode,
        };
      });
      setPendingDeviceChanges(newChanges);
    } else {
      // Reset Device Mode to original values
      const newChanges = { ...pendingDeviceChanges };
      devices.forEach((device) => {
        newChanges[device.deviceId] = {
          ...newChanges[device.deviceId],
          deviceMode: device.deviceMode || 'normal',
        };
      });
      setPendingDeviceChanges(newChanges);
    }
  };

  const handleApplyGlobalRecordingPolicy = (event) => {
    setApplyGlobalRecordingPolicy(event.target.checked);
    if (event.target.checked) {
      const newChanges = { ...pendingDeviceChanges };
      devices.forEach((device) => {
        newChanges[device.deviceId] = {
          ...newChanges[device.deviceId],
          recordingPolicy: globalRecordingPolicy,
        };
      });
      setPendingDeviceChanges(newChanges);
    } else {
      // Reset Recording Policy to original values
      const newChanges = { ...pendingDeviceChanges };
      devices.forEach((device) => {
        newChanges[device.deviceId] = {
          ...newChanges[device.deviceId],
          recordingPolicy: device.recordingPolicy || 'User',
        };
      });
      setPendingDeviceChanges(newChanges);
    }
  };

  const getRestartButtonTooltip = (deviceId) => {
    if (restartingDevices[deviceId] === 'loading') {
      return 'Restarting...';
    } else if (restartingDevices[deviceId] === 'completed') {
      return 'Restart initiated';
    } else if (restartingDevices[deviceId]) {
      const timeLeft = Math.ceil((900000 - (Date.now() - restartingDevices[deviceId])) / 60000); // Changed to 15 minutes
      return `Wait ${timeLeft} minute${timeLeft !== 1 ? 's' : ''} before restarting again`;
    }
    return 'Restart device';
  };

  const handleRestartDevice = async (deviceId) => {
    setRestartingDevices((prev) => {
      const updated = { ...prev, [deviceId]: 'loading' };
      localStorage.setItem('restartingDevices', JSON.stringify(updated));
      return updated;
    });

    // Show check mark after a brief loading animation (300ms)
    setTimeout(() => {
      setRestartingDevices((prev) => {
        const updated = { ...prev, [deviceId]: Date.now() };
        localStorage.setItem('restartingDevices', JSON.stringify(updated));
        return updated;
      });
    }, 300);

    try {
      const response = await fetch('https://utils.plix.ai/v1/restartdevice', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ deviceId: deviceId }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log(`Device ${deviceId} restart triggered:`, result);

      // Add success message
      setSnackbarMessage('Device restart initiated');
      setSnackbarOpen(true);
    } catch (error) {
      console.error(`Failed to restart device ${deviceId}:`, error);
      // Add error message
      setSnackbarMessage('Failed to restart device');
      setSnackbarOpen(true);
    }

    // Reset the button after 15 minutes
    setTimeout(() => {
      setRestartingDevices((prev) => {
        const updated = { ...prev };
        delete updated[deviceId];
        localStorage.setItem('restartingDevices', JSON.stringify(updated));
        return updated;
      });
    }, 900000); // 15 minutes
  };

  const handleEditStart = (device) => {
    setEditingDevice(device.deviceId);
    setEditValues((prev) => ({
      ...prev,
      [device.deviceId]:
        editValues[device.deviceId] ||
        pendingNameChanges[device.deviceId] ||
        device.assignedTo ||
        device.deviceName ||
        device.deviceId,
    }));
  };

  const handleEditChange = (deviceId, value) => {
    setEditValues((prev) => ({
      ...prev,
      [deviceId]: value,
    }));
    setPendingNameChanges((prev) => ({
      ...prev,
      [deviceId]: value,
    }));
  };

  const handleEditCancel = (deviceId) => {
    setEditingDevice(null);
    setEditValues((prev) => ({
      ...prev,
      [deviceId]:
        pendingNameChanges[deviceId] ||
        devices.find((d) => d.deviceId === deviceId).assignedTo ||
        devices.find((d) => d.deviceId === deviceId).deviceName ||
        deviceId,
    }));
  };

  const handleEditSave = (device) => {
    const newValue = editValues[device.deviceId];
    setPendingNameChanges((prev) => ({
      ...prev,
      [device.deviceId]: newValue,
    }));
    setEditingDevice(null);
    setUnsavedChanges(true);
  };

  const handleAddPersonnel = async (e) => {
    e.preventDefault();

    // Check if email already exists in personnel accounts
    const emailExists = personnelAccounts.some(
      (account) => account.emailAddress.toLowerCase() === newPersonnelEmail.toLowerCase()
    );

    if (emailExists) {
      setSnackbarMessage('Account with this email already exists');
      setSnackbarOpen(true);
      return;
    }

    // Validate no underscores in names
    if (newPersonnelFirstName.includes('_') || newPersonnelLastName.includes('_')) {
      setSnackbarMessage('Names cannot contain underscores');
      setSnackbarOpen(true);
      return;
    }

    try {
      // Combine names for DB storage
      const fullName = newPersonnelLastName
        ? `${newPersonnelFirstName}_${newPersonnelLastName}`
        : newPersonnelFirstName;

      const response = await createPersonnelAccount(getEffectiveUserId(), newPersonnelEmail, selectedDevices, fullName);

      const newAccount = response.organization || response;
      const currentAccounts = Array.isArray(personnelAccounts) ? personnelAccounts : [];
      setPersonnelAccounts([...currentAccounts, newAccount]);

      // Reset form
      setNewPersonnelFirstName('');
      setNewPersonnelLastName('');
      setNewPersonnelEmail('');
      setConfirmEmail('');
      setSelectedDevices([]);
      setShowAddPersonnelDialog(false);

      setSnackbarMessage('Personnel account created successfully');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error creating personnel account:', error);
      setSnackbarMessage(`Failed to create personnel account: ${error.message}`);
      setSnackbarOpen(true);
    }
  };

  const handleUpdatePersonnel = async (e) => {
    e.preventDefault();

    // Validate no underscores in names
    if (editPersonnelFirstName.includes('_') || editPersonnelLastName.includes('_')) {
      setSnackbarMessage('Names cannot contain underscores');
      setSnackbarOpen(true);
      return;
    }

    try {
      // Combine names for DB storage
      const fullName = editPersonnelLastName
        ? `${editPersonnelFirstName}_${editPersonnelLastName}`
        : editPersonnelFirstName;

      await updatePersonnelDevices(editingPersonnel.orgUserId, editSelectedDevices, fullName);

      setPersonnelAccounts(
        personnelAccounts.map((account) =>
          account.orgUserId === editingPersonnel.orgUserId
            ? {
                ...account,
                assignedTo: fullName,
                deviceIds: editSelectedDevices,
              }
            : account
        )
      );

      setEditPersonnelDialogOpen(false);
      setEditingPersonnel(null);
      setEditPersonnelFirstName('');
      setEditPersonnelLastName('');
      setEditSelectedDevices([]);

      setSnackbarMessage('Personnel account updated successfully');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error updating personnel account:', error);
      setSnackbarMessage('Failed to update personnel account');
      setSnackbarOpen(true);
    }
  };

  // Add this handler for the dialog
  const handleConfigureAllDevices = async () => {
    try {
      const effectiveUserId = getEffectiveUserId();
      const orgContainer = cosmosClient.database('Organizations').container('Organizations');

      const { resources: orgs } = await orgContainer.items
        .query({
          query: 'SELECT * FROM c WHERE c.orgUserId = @userId',
          parameters: [{ name: '@userId', value: effectiveUserId }],
        })
        .fetchAll();

      if (orgs.length === 0) {
        throw new Error('Organization not found');
      }

      const org = orgs[0];

      // Set initial bulk config based on org settings
      setBulkConfig({
        recordingMode: org.recordingPolicy === 'Shift' ? 'automatic' : 'manual',
        manualTrigger: org.buttonType || 'MULTI_TOUCH',
        keywordTriggers: org.escalationMode === 'trigger' || org.escalationMode === 'on',
        escalationTriggers: org.escalationMode === 'on',
        deviceMode: org.deviceMode || 'normal',
        deviceName: '', // This won't be used in bulk but keeping state consistent
      });

      setConfigureAllDialogOpen(true);
    } catch (error) {
      console.error('Error fetching organization defaults:', error);
      setSnackbarMessage('Failed to load organization defaults');
      setSnackbarOpen(true);
    }
  };

  const handleBulkConfigSave = async () => {
    try {
      const effectiveUserId = getEffectiveUserId();
      const orgContainer = cosmosClient.database('Organizations').container('Organizations');
      const devicesContainer = cosmosClient.database('Devices').container('Devices');

      // Update organization settings
      const {
        resources: [org],
      } = await orgContainer.items
        .query({
          query: 'SELECT * FROM c WHERE c.orgUserId = @userId',
          parameters: [{ name: '@userId', value: effectiveUserId }],
        })
        .fetchAll();

      if (!org) {
        throw new Error('Organization not found');
      }

      // Update org-level settings
      const updatedOrg = {
        ...org,
        deviceMode: bulkConfig.deviceMode,
        recordingPolicy: bulkConfig.recordingMode === 'automatic' ? 'Shift' : 'User',
        buttonType: bulkConfig.manualTrigger,
        escalationMode: bulkConfig.escalationTriggers ? 'on' : bulkConfig.keywordTriggers ? 'trigger' : 'off',
      };

      await orgContainer.item(org.id, org.orgName).replace(updatedOrg);

      // Update all devices
      const { resources: orgDevices } = await devicesContainer.items
        .query({
          query: 'SELECT * FROM c WHERE c.userId = @userId',
          parameters: [{ name: '@userId', value: effectiveUserId }],
        })
        .fetchAll();

      const updatePromises = orgDevices.map(async (device) => {
        const updatedDevice = {
          ...device,
          deviceMode: bulkConfig.deviceMode,
          recordingPolicy: bulkConfig.recordingMode === 'automatic' ? 'Shift' : 'User',
          buttonType: bulkConfig.manualTrigger,
          escalationMode: bulkConfig.escalationTriggers ? 'on' : bulkConfig.keywordTriggers ? 'trigger' : 'off',
        };

        await devicesContainer.item(device.id, device.deviceId).replace(updatedDevice);

        // Trigger device config update
        await fetch('https://utils.plix.ai/v1/updateconfig', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            deviceId: device.deviceId,
            updateType: 'fetch and update',
          }),
        });
      });

      await Promise.all(updatePromises);

      // Update local state
      setDevices(
        orgDevices.map((device) => ({
          ...device,
          deviceMode: bulkConfig.deviceMode,
          recordingPolicy: bulkConfig.recordingMode === 'automatic' ? 'Shift' : 'User',
          buttonType: bulkConfig.manualTrigger,
          escalationMode: bulkConfig.escalationTriggers ? 'on' : bulkConfig.keywordTriggers ? 'trigger' : 'off',
        }))
      );

      setConfigureAllDialogOpen(false);
      setSnackbarMessage('All devices updated successfully');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Failed to update all devices:', error);
      setSnackbarMessage('Failed to update all devices');
      setSnackbarOpen(true);
    }
  };

  // Add this right before the devices table
  const configureAllDevicesButton = (
    <Box sx={{ mb: 2 }}>
      <Button
        variant="contained"
        onClick={handleConfigureAllDevices}
        startIcon={<ConstructionIcon />}
        sx={{
          backgroundColor: '#cb2d3e',
          '&:hover': {
            backgroundColor: '#b52d2e',
          },
        }}
      >
        Configure All Devices
      </Button>
    </Box>
  );

  // Add this dialog component
  const configureAllDevicesDialog = (
    <Dialog open={configureAllDialogOpen} onClose={() => setConfigureAllDialogOpen(false)} maxWidth="sm" fullWidth>
      <DialogTitle>
        Configure All Devices
        <IconButton
          aria-label="close"
          onClick={() => setConfigureAllDialogOpen(false)}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 1 }}>
          {' '}
          {/* Reduced gap from 3 to 2, pt from 2 to 1 */}
          {/* <Box sx={{ p: 2, bgcolor: '#f8f9fa', borderRadius: 1, mb: 2 }}> 
            <Typography variant="h6" sx={{ color: '#1976d2', mb: 1 }}>
              Bulk Configuration Mode
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Changes made here will apply to all devices. Individual device settings will be overwritten.
            </Typography>
          </Box> */}
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {' '}
            {/* Reduced gap from 3 to 2 */}
            {/* Device Feedback section */}
            <Box sx={{ mb: 2 }}>
              {' '}
              {/* Reduced mb from 3 to 2 */}
              <div className="device-dialog-heading">
                <Typography variant="h6">Device Feedback</Typography>
                <StyledTooltip title={<div dangerouslySetInnerHTML={{ __html: DEVICE_FEEDBACK_INFO }} />}>
                  <InfoIcon sx={{ ml: 1, fontSize: 20, color: 'action.active', cursor: 'help' }} />
                </StyledTooltip>
              </div>
              <FormControl fullWidth>
                <Select
                  value={bulkConfig.deviceMode}
                  onChange={(e) => setBulkConfig({ ...bulkConfig, deviceMode: e.target.value })}
                  fullWidth
                >
                  <MenuItem value="normal">Balanced</MenuItem>
                  <MenuItem value="deterrent">Deterrent</MenuItem>
                  <MenuItem value="stealth">Stealth</MenuItem>
                </Select>
              </FormControl>
            </Box>
            {/* Recording Activation section */}
            <Box sx={{ mb: 0 }}>
              {' '}
              {/* Changed mb from 1 to 0 since it's the last section */}
              <div className="device-dialog-heading">
                <Typography variant="h6">Recording Activation</Typography>
                <StyledTooltip title={<div dangerouslySetInnerHTML={{ __html: RECORDING_ACTIVATION_INFO }} />}>
                  <InfoIcon sx={{ ml: 1, fontSize: 20, color: 'action.active', cursor: 'help' }} />
                </StyledTooltip>
              </div>
              <FormControl component="fieldset" fullWidth>
                <RadioGroup
                  value={bulkConfig.recordingMode}
                  onChange={(e) =>
                    setBulkConfig({
                      ...bulkConfig,
                      recordingMode: e.target.value,
                      keywordTriggers: e.target.value === 'automatic' ? false : bulkConfig.keywordTriggers,
                      escalationTriggers: e.target.value === 'automatic' ? false : bulkConfig.escalationTriggers,
                    })
                  }
                >
                  <FormControlLabel value="automatic" control={<Radio />} label="Shift Based" />
                  <FormControlLabel value="manual" control={<Radio />} label="Flexible Control" />
                </RadioGroup>
              </FormControl>
              {bulkConfig.recordingMode === 'manual' && (
                <Box sx={{ ml: 4, display: 'flex', flexDirection: 'column', gap: 2 }}>
                  {' '}
                  {/* Added gap: 2 for consistent spacing */}
                  {/* Manual Trigger subsection */}
                  <Box sx={{ mt: 1 }}>
                    {' '}
                    {/* Added small top margin */}
                    <div className="device-dialog-subheading">
                      <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                        Manual Trigger
                      </Typography>
                      <StyledTooltip title="Configure how to manually turn on video recording.">
                        <InfoIcon sx={{ ml: 1, fontSize: 18, color: 'action.active', cursor: 'help' }} />
                      </StyledTooltip>
                    </div>
                    <FormControl fullWidth sx={{ mt: 1 }}>
                      <Select
                        value={bulkConfig.manualTrigger}
                        onChange={(e) => setBulkConfig({ ...bulkConfig, manualTrigger: e.target.value })}
                        displayEmpty
                      >
                        <MenuItem value="SINGLE_TAP">Single Tap</MenuItem>
                        <MenuItem value="DOUBLE_TAP">Double Tap</MenuItem>
                        <MenuItem value="MULTI_TOUCH">Two-Finger Hold</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  {/* Smart Trigger subsection */}
                  <Box>
                    <div className="device-dialog-subheading">
                      <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                        Smart Trigger
                      </Typography>
                      <StyledTooltip title={<div dangerouslySetInnerHTML={{ __html: SMART_TRIGGER_INFO }} />}>
                        <InfoIcon sx={{ ml: 1, fontSize: 18, color: 'action.active', cursor: 'help' }} />
                      </StyledTooltip>
                    </div>
                    <FormControl fullWidth sx={{ mt: 1 }}>
                      <Select
                        value={bulkConfig.escalationTriggers ? 'all' : bulkConfig.keywordTriggers ? 'keywords' : 'none'}
                        onChange={(e) => {
                          const value = e.target.value;
                          setBulkConfig({
                            ...bulkConfig,
                            keywordTriggers: value === 'keywords' || value === 'all',
                            escalationTriggers: value === 'all',
                          });
                        }}
                      >
                        <MenuItem value="all">All Triggers</MenuItem>
                        <MenuItem value="keywords">Trigger Phrases Only</MenuItem>
                        <MenuItem value="none">Never</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setConfigureAllDialogOpen(false)}>Cancel</Button>
        <Button
          onClick={handleBulkConfigSave}
          variant="contained"
          sx={{
            backgroundColor: '#cb2d3e',
            '&:hover': {
              backgroundColor: '#b52d2e',
            },
          }}
        >
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );

  // Add new handler for single device configuration
  const handleConfigureDevice = (device) => {
    setBulkConfig({
      recordingMode: currentDeviceValues[device.deviceId]?.recordingPolicy === 'Shift' ? 'automatic' : 'manual',
      manualTrigger: currentDeviceValues[device.deviceId]?.buttonType || 'MULTI_TOUCH',
      keywordTriggers:
        currentDeviceValues[device.deviceId]?.escalationMode === 'trigger' ||
        currentDeviceValues[device.deviceId]?.escalationMode === 'on',
      escalationTriggers: currentDeviceValues[device.deviceId]?.escalationMode === 'on',
      deviceMode: currentDeviceValues[device.deviceId]?.deviceMode || 'normal',
      deviceName:
        editValues[device.deviceId] ||
        pendingNameChanges[device.deviceId] ||
        device.assignedTo ||
        device.deviceName ||
        device.deviceId,
    });
    setEditingDevice(device.deviceId);
    setConfigureDeviceDialogOpen(true);
  };

  // Add the device configuration dialog
  const configureDeviceDialog = (
    <Dialog
      open={configureDeviceDialogOpen}
      onClose={() => setConfigureDeviceDialogOpen(false)}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        Configure {editingDevice}
        <IconButton
          aria-label="close"
          onClick={() => setConfigureDeviceDialogOpen(false)}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 1 }}>
          {' '}
          {/* Reduced gap from 3 to 2, pt from 2 to 1 */}
          {/* Device Name section */}
          {editingDevice && (
            <Box sx={{ mb: 2 }}>
              {' '}
              {/* Reduced mb from 3 to 2 */}
              <div className="device-dialog-heading">
                <Typography variant="h6">Device Name</Typography>
                <StyledTooltip title="Assign a friendly name to this device for easy identification">
                  <InfoIcon sx={{ ml: 1, fontSize: 20, color: 'action.active', cursor: 'help' }} />
                </StyledTooltip>
              </div>
              <TextField
                fullWidth
                value={bulkConfig.deviceName}
                onChange={(e) => setBulkConfig({ ...bulkConfig, deviceName: e.target.value })}
              />
            </Box>
          )}
          {/* Device Feedback section */}
          <Box sx={{ mb: 2 }}>
            {' '}
            {/* Reduced mb from 3 to 2 */}
            <div className="device-dialog-heading">
              <Typography variant="h6">Device Feedback</Typography>
              <StyledTooltip title={<div dangerouslySetInnerHTML={{ __html: DEVICE_FEEDBACK_INFO }} />}>
                <InfoIcon sx={{ ml: 1, fontSize: 20, color: 'action.active', cursor: 'help' }} />
              </StyledTooltip>
            </div>
            <FormControl fullWidth>
              <Select
                value={bulkConfig.deviceMode}
                onChange={(e) => setBulkConfig({ ...bulkConfig, deviceMode: e.target.value })}
                fullWidth
              >
                <MenuItem value="normal">Balanced</MenuItem>
                <MenuItem value="deterrent">Deterrent</MenuItem>
                <MenuItem value="stealth">Stealth</MenuItem>
              </Select>
            </FormControl>
          </Box>
          {/* Recording Activation section */}
          <Box sx={{ mb: 0 }}>
            {' '}
            {/* Changed mb from 1 to 0 since it's the last section */}
            <div className="device-dialog-heading">
              <Typography variant="h6">Recording Activation</Typography>
              <StyledTooltip title={<div dangerouslySetInnerHTML={{ __html: RECORDING_ACTIVATION_INFO }} />}>
                <InfoIcon sx={{ ml: 1, fontSize: 20, color: 'action.active', cursor: 'help' }} />
              </StyledTooltip>
            </div>
            <FormControl component="fieldset" fullWidth>
              <RadioGroup
                value={bulkConfig.recordingMode}
                onChange={(e) =>
                  setBulkConfig({
                    ...bulkConfig,
                    recordingMode: e.target.value,
                    keywordTriggers: e.target.value === 'automatic' ? false : bulkConfig.keywordTriggers,
                    escalationTriggers: e.target.value === 'automatic' ? false : bulkConfig.escalationTriggers,
                  })
                }
              >
                <FormControlLabel value="automatic" control={<Radio />} label="Automatic (Shift-based)" />
                <FormControlLabel value="manual" control={<Radio />} label="Flexible Control" />
              </RadioGroup>
            </FormControl>
            {bulkConfig.recordingMode === 'manual' && (
              <Box sx={{ ml: 4, display: 'flex', flexDirection: 'column', gap: 2 }}>
                {' '}
                {/* Added gap: 2 for consistent spacing */}
                {/* Manual Trigger subsection */}
                <Box sx={{ mt: 1 }}>
                  {' '}
                  {/* Added small top margin */}
                  <div className="device-dialog-subheading">
                    <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                      Manual Trigger
                    </Typography>
                    <StyledTooltip title="Configure how to manually turn on video recording.">
                      <InfoIcon sx={{ ml: 1, fontSize: 18, color: 'action.active', cursor: 'help' }} />
                    </StyledTooltip>
                  </div>
                  <FormControl fullWidth sx={{ mt: 1 }}>
                    <Select
                      value={bulkConfig.manualTrigger}
                      onChange={(e) => setBulkConfig({ ...bulkConfig, manualTrigger: e.target.value })}
                      displayEmpty
                    >
                      <MenuItem value="SINGLE_TAP">Single Tap</MenuItem>
                      <MenuItem value="DOUBLE_TAP">Double Tap</MenuItem>
                      <MenuItem value="MULTI_TOUCH">Two-Finger Hold</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                {/* Smart Trigger subsection */}
                <Box>
                  <div className="device-dialog-subheading">
                    <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                      Smart Trigger
                    </Typography>
                    <StyledTooltip title={<div dangerouslySetInnerHTML={{ __html: SMART_TRIGGER_INFO }} />}>
                      <InfoIcon sx={{ ml: 1, fontSize: 18, color: 'action.active', cursor: 'help' }} />
                    </StyledTooltip>
                  </div>
                  <FormControl fullWidth sx={{ mt: 1 }}>
                    <Select
                      value={bulkConfig.escalationTriggers ? 'all' : bulkConfig.keywordTriggers ? 'keywords' : 'none'}
                      onChange={(e) => {
                        const value = e.target.value;
                        setBulkConfig({
                          ...bulkConfig,
                          keywordTriggers: value === 'keywords' || value === 'all',
                          escalationTriggers: value === 'all',
                        });
                      }}
                    >
                      <MenuItem value="all">All Triggers</MenuItem>
                      <MenuItem value="keywords">Trigger Phrases Only</MenuItem>
                      <MenuItem value="none">Never</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setConfigureDeviceDialogOpen(false)}>Cancel</Button>
        <Button
          onClick={() => handleSaveDeviceConfig(editingDevice)}
          variant="contained"
          sx={{
            backgroundColor: '#cb2d3e',
            '&:hover': {
              backgroundColor: '#b52d2e',
            },
          }}
        >
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );

  // Add handler for saving device configuration
  const handleSaveDeviceConfig = async (deviceId) => {
    try {
      const devicesContainer = cosmosClient.database('Devices').container('Devices');

      // Find the device to update
      const {
        resources: [device],
      } = await devicesContainer.items
        .query({
          query: 'SELECT * FROM c WHERE c.deviceId = @deviceId',
          parameters: [{ name: '@deviceId', value: deviceId }],
        })
        .fetchAll();

      if (!device) {
        throw new Error('Device not found');
      }

      // Prepare the updated device configuration
      const updatedDevice = {
        ...device,
        deviceMode: bulkConfig.deviceMode,
        recordingPolicy: bulkConfig.recordingMode === 'automatic' ? 'Shift' : 'User',
        buttonType: bulkConfig.manualTrigger,
        escalationMode: bulkConfig.escalationTriggers ? 'on' : bulkConfig.keywordTriggers ? 'trigger' : 'off',
        assignedTo: bulkConfig.deviceName,
      };

      // Save the updated device
      await devicesContainer.item(device.id, device.deviceId).replace(updatedDevice);

      // Update the local state
      setDevices(devices.map((d) => (d.deviceId === deviceId ? updatedDevice : d)));

      // Trigger device config update
      await fetch('https://utils.plix.ai/v1/updateconfig', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          deviceId: deviceId,
          updateType: 'fetch and update',
        }),
      });

      setConfigureDeviceDialogOpen(false);
      setSnackbarMessage('Device configuration updated successfully');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Failed to update device configuration:', error);
      setSnackbarMessage('Failed to update device configuration');
      setSnackbarOpen(true);
    }
  };

  const deviceTableContent = (
    <TableContainer style={{ maxHeight: '400px', overflow: 'auto', overflowX: 'auto' }}>
      <Table stickyHeader sx={{ minWidth: isMobile ? 300 : 650 }} aria-label="device settings table">
        <TableHead>
          <TableRow>
            <TableCell align="left" style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
              <TableSortLabel
                active={orderBy === 'deviceId'}
                direction={orderBy === 'deviceId' ? order : 'asc'}
                onClick={() => handleRequestSort('deviceId')}
              >
                Device ID
              </TableSortLabel>
            </TableCell>
            <TableCell align="left" style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
              Device Name
            </TableCell>
            <TableCell align="right" style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedDevices
            .filter((device) => device.deviceId !== 'All Devices')
            .map((device) => (
              <TableRow key={device.deviceId}>
                <TableCell component="th" scope="row" align="left">
                  {device.deviceId}
                </TableCell>
                <TableCell align="left">
                  {editValues[device.deviceId] ??
                    pendingNameChanges[device.deviceId] ??
                    device.assignedTo ??
                    device.deviceName ??
                    device.deviceId}
                </TableCell>
                <TableCell align="right">
                  <Tooltip title="Configure device">
                    <IconButton onClick={() => handleConfigureDevice(device)} size="small" sx={{ marginRight: 1 }}>
                      <ConstructionIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={getRestartButtonTooltip(device.deviceId)}>
                    <span>
                      {' '}
                      {/* Wrap disabled button in span to show tooltip when disabled */}
                      <IconButton
                        onClick={() => handleRestartDevice(device.deviceId)}
                        disabled={!!restartingDevices[device.deviceId]}
                        size="small"
                        sx={{
                          color: '#cb2d3e',
                          '&:hover': {
                            backgroundColor: 'rgba(203, 45, 62, 0.04)',
                          },
                        }}
                      >
                        {restartingDevices[device.deviceId] === 'loading' ? (
                          <CircularProgress size={20} sx={{ color: '#cb2d3e' }} />
                        ) : restartingDevices[device.deviceId] ? (
                          <CheckIcon fontSize="small" />
                        ) : (
                          <RestartAltIcon fontSize="small" />
                        )}
                      </IconButton>
                    </span>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const handleAddReport = async (e) => {
    e.preventDefault();
    try {
      const effectiveUserId = getEffectiveUserId();
      const orgContainer = cosmosClient.database('Organizations').container('Organizations');

      // Fetch organization
      const { resources: orgs } = await orgContainer.items
        .query({
          query: 'SELECT * FROM c WHERE c.orgUserId = @userId',
          parameters: [{ name: '@userId', value: effectiveUserId }],
        })
        .fetchAll();

      if (orgs.length === 0) {
        throw new Error('Organization not found');
      }

      const org = orgs[0];
      const updatedReportSubscribers = [...(org.reportSubscribers || []), newReport];

      // Update organization with new report subscriber
      const updatedOrg = {
        ...org,
        reportSubscribers: updatedReportSubscribers,
      };

      await orgContainer.item(org.id, org.orgName).replace(updatedOrg);

      // Update local state
      setReportSubscribers(updatedReportSubscribers);
      setShowAddReportDialog(false);
      setNewReport({
        emailAddress: '',
        frequency: 'weekly',
        timeOfDay: '16:00',
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        reportType: 'site',
      });

      // Show success message
      setSnackbarMessage('Report subscription added successfully');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Failed to add report subscription:', error);
      setSnackbarMessage('Failed to add report subscription');
      setSnackbarOpen(true);
    }
  };

  const handleEditReport = (report) => {
    setEditingReport(report);
    setNewReport(report);
    setShowAddReportDialog(true);
  };

  const handleUpdateReport = async (e) => {
    e.preventDefault();
    try {
      const effectiveUserId = getEffectiveUserId();
      const orgContainer = cosmosClient.database('Organizations').container('Organizations');

      // Fetch organization
      const { resources: orgs } = await orgContainer.items
        .query({
          query: 'SELECT * FROM c WHERE c.orgUserId = @userId',
          parameters: [{ name: '@userId', value: effectiveUserId }],
        })
        .fetchAll();

      if (orgs.length === 0) {
        throw new Error('Organization not found');
      }

      const org = orgs[0];
      const updatedReportSubscribers = reportSubscribers.map((report) =>
        report === editingReport ? newReport : report
      );

      // Update organization with modified report subscribers
      const updatedOrg = {
        ...org,
        reportSubscribers: updatedReportSubscribers,
      };

      await orgContainer.item(org.id, org.orgName).replace(updatedOrg);

      // Update local state
      setReportSubscribers(updatedReportSubscribers);
      setShowAddReportDialog(false);
      setEditingReport(null);

      // Show success message
      setSnackbarMessage('Report subscription updated successfully');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Failed to update report subscription:', error);
      setSnackbarMessage('Failed to update report subscription');
      setSnackbarOpen(true);
    }
  };

  const handleDeleteReport = async (reportToDelete) => {
    try {
      const effectiveUserId = getEffectiveUserId();
      const orgContainer = cosmosClient.database('Organizations').container('Organizations');

      // Fetch organization
      const { resources: orgs } = await orgContainer.items
        .query({
          query: 'SELECT * FROM c WHERE c.orgUserId = @userId',
          parameters: [{ name: '@userId', value: effectiveUserId }],
        })
        .fetchAll();

      if (orgs.length === 0) {
        throw new Error('Organization not found');
      }

      const org = orgs[0];
      const updatedReportSubscribers = reportSubscribers.filter((report) => report !== reportToDelete);

      // Update organization with filtered report subscribers
      const updatedOrg = {
        ...org,
        reportSubscribers: updatedReportSubscribers,
      };

      await orgContainer.item(org.id, org.orgName).replace(updatedOrg);

      // Update local state
      setReportSubscribers(updatedReportSubscribers);

      // Show success message
      setSnackbarMessage('Report subscription deleted successfully');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Failed to delete report subscription:', error);
      setSnackbarMessage('Failed to delete report subscription');
      setSnackbarOpen(true);
    }
  };

  const reportsSection = (
    <div className="configuration-section" ref={sectionRefs['Reports']}>
      <h2>Activity Reports</h2>
      <div className="activity-report-info">
        <FontAwesomeIcon icon={faMailBulk} className="activity-report-icon" />
        <span>
          Receive recurring reports about personnel or sites visited.
          <br />
          <p className="activity-report-detail">
            Each report email includes Excel attachments with detailed activity and metrics.
          </p>
        </span>
      </div>

      {reportSubscribers.length > 0 && (
        <TableContainer component={Paper} style={{ marginBottom: '20px' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>Frequency</TableCell>
                <TableCell>Time</TableCell>
                <TableCell>Report Type</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reportSubscribers.map((subscriber, index) => (
                <TableRow key={index}>
                  <TableCell>{subscriber.emailAddress}</TableCell>
                  <TableCell style={{ textTransform: 'capitalize' }}>{subscriber.frequency}</TableCell>
                  <TableCell>
                    {new Date(`2000-01-01T${subscriber.timeOfDay}`).toLocaleTimeString([], {
                      hour: 'numeric',
                      minute: '2-digit',
                      hour12: true,
                      timeZone: subscriber.timeZone,
                    })}
                    {` (${subscriber.timeZone})`}
                  </TableCell>
                  <TableCell style={{ textTransform: 'capitalize' }}>{subscriber.reportType}</TableCell>
                  <TableCell align="right">
                    <Tooltip title="Send report now">
                      <IconButton onClick={() => handleSendNow(subscriber)} size="small" sx={{ marginRight: 1 }}>
                        <SendIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit subscription">
                      <IconButton onClick={() => handleEditReport(subscriber)} size="small" sx={{ marginRight: 1 }}>
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete subscription">
                      <IconButton onClick={() => handleDeleteReport(subscriber)} size="small">
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Button
        variant="contained"
        color="primary"
        onClick={() => setShowAddReportDialog(true)}
        className="add-subscriber-button"
      >
        <FontAwesomeIcon icon={faPlus} className="button-icon" />
        Add Report Subscriber
      </Button>

      <Dialog
        open={showAddReportDialog}
        onClose={() => {
          setShowAddReportDialog(false);
          setEditingReport(null);
          setNewReport({
            emailAddress: '',
            frequency: 'weekly',
            timeOfDay: '16:00',
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            reportType: 'site',
          });
        }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          {editingReport ? 'Edit Report Subscription' : 'Add Report Subscription'}
          <IconButton
            aria-label="close"
            onClick={() => {
              setShowAddReportDialog(false);
              setEditingReport(null);
            }}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, pt: 2 }}>
            <TextField
              label="Email Address"
              type="email"
              value={newReport.emailAddress}
              onChange={(e) => setNewReport({ ...newReport, emailAddress: e.target.value })}
              fullWidth
              required
              disabled={editingReport}
              error={!newReport.emailAddress}
            />

            <FormControl fullWidth required error={!newReport.reportType}>
              <InputLabel>Report Type</InputLabel>
              <Select
                value={newReport.reportType}
                onChange={(e) => setNewReport({ ...newReport, reportType: e.target.value })}
                label="Report Type"
              >
                <MenuItem value="site">Site Activity</MenuItem>
                <MenuItem value="personnel">Personnel Activity</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth required error={!newReport.frequency}>
              <InputLabel>Frequency</InputLabel>
              <Select
                value={newReport.frequency}
                onChange={(e) => setNewReport({ ...newReport, frequency: e.target.value })}
                label="Frequency"
              >
                <MenuItem value="daily">Daily</MenuItem>
                <MenuItem value="weekly">Weekly</MenuItem>
                <MenuItem value="monthly">Monthly</MenuItem>
              </Select>
            </FormControl>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                label="Time of Day"
                value={newReport.timeOfDay ? dayjs(`2000-01-01T${newReport.timeOfDay}`) : null}
                onChange={(newValue) =>
                  setNewReport({
                    ...newReport,
                    timeOfDay: newValue ? newValue.format('HH:mm') : '',
                  })
                }
                sx={{ width: '100%' }}
                slotProps={{
                  textField: {
                    required: true,
                    error: !newReport.timeOfDay,
                  },
                }}
              />
            </LocalizationProvider>

            <FormControl fullWidth required error={!newReport.timeZone}>
              <InputLabel>Time Zone</InputLabel>
              <Select
                value={newReport.timeZone}
                onChange={(e) => setNewReport({ ...newReport, timeZone: e.target.value })}
                label="Time Zone"
              >
                {commonTimezones.map((tz) => (
                  <MenuItem key={tz} value={tz}>
                    {tz}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowAddReportDialog(false);
              setEditingReport(null);
            }}
            color="inherit"
          >
            Cancel
          </Button>
          <Button
            onClick={editingReport ? handleUpdateReport : handleAddReport}
            variant="contained"
            disabled={
              !newReport.emailAddress ||
              !newReport.reportType ||
              !newReport.frequency ||
              !newReport.timeOfDay ||
              !newReport.timeZone
            }
            sx={{
              backgroundColor: '#cb2d3e',
              '&:hover': {
                backgroundColor: '#b52d2e',
              },
            }}
          >
            {editingReport ? 'Save Changes' : 'Save Subscription'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );

  // Update the Add Personnel Dialog JSX
  const addPersonnelDialog = (
    <Dialog open={showAddPersonnelDialog} onClose={() => setShowAddPersonnelDialog(false)}>
      <DialogTitle>Add Personnel Account</DialogTitle>
      <DialogContent>
        <form onSubmit={handleAddPersonnel} className="personnel-form">
          <TextField
            required
            label="First Name"
            value={newPersonnelFirstName}
            onChange={(e) => setNewPersonnelFirstName(e.target.value)}
            error={newPersonnelFirstName.includes('_')}
            helperText={newPersonnelFirstName.includes('_') ? 'Name cannot contain underscore' : ''}
          />
          <TextField
            label="Last Name"
            value={newPersonnelLastName}
            onChange={(e) => setNewPersonnelLastName(e.target.value)}
            error={newPersonnelLastName.includes('_')}
            helperText={newPersonnelLastName.includes('_') ? 'Name cannot contain underscore' : ''}
          />
          <TextField
            label="Email"
            type="email"
            value={newPersonnelEmail}
            onChange={(e) => setNewPersonnelEmail(e.target.value)}
            required
            fullWidth
          />
          <TextField
            label="Confirm Email"
            type="email"
            value={confirmEmail}
            onChange={(e) => setConfirmEmail(e.target.value)}
            required
            fullWidth
            error={confirmEmail !== '' && confirmEmail !== newPersonnelEmail}
            helperText={confirmEmail !== '' && confirmEmail !== newPersonnelEmail ? 'Emails do not match' : ''}
          />
          <div className="device-selection">
            <Typography variant="subtitle1" gutterBottom>
              Select Devices
            </Typography>
            <TableContainer component={Paper} sx={{ maxHeight: 200 }}>
              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        indeterminate={selectedDevices.length > 0 && selectedDevices.length < devices.length}
                        checked={devices.length > 0 && selectedDevices.length === devices.length}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedDevices(devices.map((d) => d.deviceId));
                          } else {
                            setSelectedDevices([]);
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell>Device ID</TableCell>
                    <TableCell>Device Name</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {devices.map((device) => (
                    <TableRow key={device.deviceId}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedDevices.includes(device.deviceId)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedDevices([...selectedDevices, device.deviceId]);
                            } else {
                              setSelectedDevices(selectedDevices.filter((id) => id !== device.deviceId));
                            }
                          }}
                        />
                      </TableCell>
                      <TableCell>{device.deviceId}</TableCell>
                      <TableCell>{device.assignedTo || pendingNameChanges[device.deviceId] || 'Unassigned'}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <DialogActions className="form-actions">
            <Button onClick={() => setShowAddPersonnelDialog(false)} color="inherit">
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={
                !newPersonnelFirstName ||
                !newPersonnelLastName ||
                !newPersonnelEmail ||
                newPersonnelEmail !== confirmEmail ||
                selectedDevices.length === 0
              }
            >
              Add Account
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );

  // Update the Edit Personnel Dialog JSX
  const editPersonnelDialog = (
    <Dialog open={editPersonnelDialogOpen} onClose={() => setEditPersonnelDialogOpen(false)}>
      <DialogTitle>Edit Personnel Account</DialogTitle>
      <DialogContent>
        <form onSubmit={handleUpdatePersonnel} className="personnel-form">
          <TextField
            required
            label="First Name"
            value={editPersonnelFirstName}
            onChange={(e) => setEditPersonnelFirstName(e.target.value)}
            error={editPersonnelFirstName.includes('_')}
            helperText={editPersonnelFirstName.includes('_') ? 'Name cannot contain underscore' : ''}
          />
          <TextField
            label="Last Name"
            value={editPersonnelLastName}
            onChange={(e) => setEditPersonnelLastName(e.target.value)}
            error={editPersonnelLastName.includes('_')}
            helperText={editPersonnelLastName.includes('_') ? 'Name cannot contain underscore' : ''}
          />
          <TextField label="Email" type="email" value={editingPersonnel?.emailAddress || ''} disabled fullWidth />
          <div className="device-selection">
            <Typography variant="subtitle1" gutterBottom>
              Select Devices
            </Typography>
            <TableContainer component={Paper} sx={{ maxHeight: 200 }}>
              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        indeterminate={editSelectedDevices.length > 0 && editSelectedDevices.length < devices.length}
                        checked={devices.length > 0 && editSelectedDevices.length === devices.length}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setEditSelectedDevices(devices.map((d) => d.deviceId));
                          } else {
                            setEditSelectedDevices([]);
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell>Device ID</TableCell>
                    <TableCell>Device Name</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {devices.map((device) => (
                    <TableRow key={device.deviceId}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={editSelectedDevices.includes(device.deviceId)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setEditSelectedDevices([...editSelectedDevices, device.deviceId]);
                            } else {
                              setEditSelectedDevices(editSelectedDevices.filter((id) => id !== device.deviceId));
                            }
                          }}
                        />
                      </TableCell>
                      <TableCell>{device.deviceId}</TableCell>
                      <TableCell>{device.assignedTo || pendingNameChanges[device.deviceId] || 'Unassigned'}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <DialogActions className="form-actions">
            <Button onClick={() => setEditPersonnelDialogOpen(false)} color="inherit">
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!editPersonnelFirstName || editSelectedDevices.length === 0}
            >
              Save Changes
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );

  // Add this helper function near the top of your component
  const formatDisplayName = (name) => {
    if (!name) return '';
    return name.split('_').join(' ');
  };

  return (
    <div className="configuration-wrapper">
      <div className="configuration-header">
        <div className="configuration-header-left">
          <Typography variant="h1" className="configuration-title">
            Configuration
          </Typography>
        </div>
      </div>
      <div className={`configuration-content ${isMobile ? 'mobile' : ''}`}>
        {!isMobile && (
          <div className="table-of-contents">
            <a
              href="#Devices"
              className={activeSection === 'Devices' ? 'active' : ''}
              onClick={(e) => {
                e.preventDefault();
                handleTableOfContentsClick('Devices');
              }}
            >
              Devices
            </a>
            {!isGuard && (
              <>
                <a
                  href="#Personnel"
                  className={activeSection === 'Personnel' ? 'active' : ''}
                  onClick={(e) => {
                    e.preventDefault();
                    handleTableOfContentsClick('Personnel');
                  }}
                >
                  Personnel Accounts
                </a>
                <a
                  href="#Trigger Phrases"
                  className={activeSection === 'Trigger Phrases' ? 'active' : ''}
                  onClick={(e) => {
                    e.preventDefault();
                    handleTableOfContentsClick('Trigger Phrases');
                  }}
                >
                  Trigger Phrases
                </a>
                <a
                  href="#Reports"
                  className={activeSection === 'Reports' ? 'active' : ''}
                  onClick={(e) => {
                    e.preventDefault();
                    handleTableOfContentsClick('Reports');
                  }}
                >
                  Activity Reports
                </a>
              </>
            )}
          </div>
        )}
        <div className="configuration-container">
          <div className="configuration-section devices-configuration-section" ref={sectionRefs['Devices']}>
            <h2>{isGuard ? 'Your Device' : 'Devices'}</h2>
            {/* Add the button here */}
            {!isGuard && configureAllDevicesButton}
            <div className="trigger-info">
              <FontAwesomeIcon icon={faCompass} className="trigger-icon" />
              <span>Manage and configure device controls.</span>
            </div>
            {/* <div className="policy-description">
            <span>Configure your devices here.</span>
          </div> */}
            {/* <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <FormControl sx={{ width: '250px' }}>
                <FormLabel>
                  <Switch
                    checked={applyGlobalDeviceMode}
                    onChange={handleApplyGlobalDeviceMode}
                    name="applyGlobalDeviceMode"
                  />
                  Global Device Mode
                </FormLabel>
                <Select
                  value={globalDeviceMode}
                  onChange={handleGlobalDeviceModeChange}
                  disabled={!applyGlobalDeviceMode}
                  sx={{ width: '200px' }}
                >
                  <MenuItem value="normal">Normal</MenuItem>
                  <MenuItem value="stealth">Stealth</MenuItem>
                  <MenuItem value="deterrent">Deterrent</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <FormControl sx={{ width: '250px' }}>
                <FormLabel>
                  <Switch
                    checked={applyGlobalRecordingPolicy}
                    onChange={handleApplyGlobalRecordingPolicy}
                    name="applyGlobalRecordingPolicy"
                  />
                  Global Recording Policy
                </FormLabel>
                <Select
                  value={globalRecordingPolicy}
                  onChange={handleGlobalRecordingPolicyChange}
                  disabled={!applyGlobalRecordingPolicy}
                  sx={{ width: '200px' }}
                >
                  <MenuItem value="User">User</MenuItem>
                  <MenuItem value="Always">Always</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box> */}
            {/* <br></br> */}
            <Paper style={{ width: '100%', overflow: 'hidden' }}>{deviceTableContent}</Paper>
            {/* Comment out the description under the table
          <div className="option-description" style={{ marginTop: '1rem' }}>
            <h3>Device Mode:</h3>
            <strong>Normal Mode:</strong> Standard device operation with audio feedback.<br />
            <strong>Stealth Mode:</strong> Quiet operation with minimal visual and audio cues.<br />
            <strong>Deterrent Mode:</strong> Enhanced audio and visual cues for maximum visibility.<br />
            <h3>Recording Policy:</h3>
            <strong>User:</strong> Recording starts when triggered by the user or a trigger phrase.<br />
            <strong>Always:</strong> The device is always recording.
          </div>
          */}

            <br></br>
            <br></br>
          </div>
          {!isGuard && (
            <div className="configuration-section" ref={sectionRefs['Personnel']}>
              <h2>Personnel Accounts</h2>
              <div className="personnel-info">
                <FontAwesomeIcon icon={faUsers} className="personnel-icon" />
                <span>
                  Create and manage personnel accounts for your organization.
                  <br />
                </span>
              </div>

              {personnelAccounts.length > 0 && (
                <TableContainer component={Paper} style={{ marginBottom: '20px' }}>
                  <Table aria-label="personnel accounts table" className="personnel-accounts-table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Device ID</TableCell>
                        <TableCell align="right">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {personnelAccounts.map((account) => (
                        <TableRow key={account.emailAddress}>
                          <TableCell>{formatDisplayName(account.assignedTo)}</TableCell>
                          <TableCell>{account.emailAddress}</TableCell>
                          <TableCell>
                            {(() => {
                              const deviceNames = account.deviceIds.map((deviceId) => {
                                const device = devices.find((d) => d.deviceId === deviceId);
                                return device?.deviceName || device?.assignedTo || deviceId;
                              });

                              const tooltipContent = (
                                <div
                                  style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    maxWidth: '300px',
                                  }}
                                >
                                  {deviceNames.map((name, index) => (
                                    <div key={index} className="personnel-device-chip">
                                      {name}
                                    </div>
                                  ))}
                                </div>
                              );

                              const content = (
                                <div
                                  style={{
                                    display: 'flex',
                                    gap: '4px',
                                    alignItems: 'center',
                                    maxWidth: '200px',
                                    overflow: 'hidden',
                                  }}
                                >
                                  {deviceNames.slice(0, 2).map((name, index) => (
                                    <div
                                      key={index}
                                      style={{
                                        backgroundColor: '#f0f0f0',
                                        padding: '2px 8px',
                                        borderRadius: '12px',
                                        fontSize: '0.875rem',
                                        whiteSpace: 'nowrap',
                                      }}
                                    >
                                      {name}
                                    </div>
                                  ))}
                                  {deviceNames.length > 2 && (
                                    <div
                                      style={{
                                        backgroundColor: '#ffffff',
                                        border: '1px solid #e0e0e0',
                                        padding: '2px 8px',
                                        borderRadius: '12px',
                                        fontSize: '0.75rem',
                                        color: '#666666',
                                        whiteSpace: 'nowrap',
                                      }}
                                    >
                                      +{deviceNames.length - 2}
                                    </div>
                                  )}
                                </div>
                              );

                              return deviceNames.length > 2 ? (
                                <Tooltip
                                  title={tooltipContent}
                                  arrow={false}
                                  classes={{
                                    tooltip: 'personnel-device-tooltip',
                                  }}
                                >
                                  {content}
                                </Tooltip>
                              ) : (
                                content
                              );
                            })()}
                          </TableCell>
                          <TableCell align="right">
                            <Tooltip title="Edit account">
                              <IconButton
                                onClick={() => handleEditPersonnel(account)}
                                size="small"
                                sx={{ marginRight: 1 }}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete account">
                              <IconButton onClick={() => handleDeletePersonnel(account)} size="small">
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}

              <Button
                variant="contained"
                color="primary"
                onClick={() => setShowAddPersonnelDialog(true)}
                className="add-account-button"
              >
                <FontAwesomeIcon icon={faPlus} className="button-icon" />
                Add Account
              </Button>

              {addPersonnelDialog}
            </div>
          )}
          {!isGuard && (
            <>
              <div classNameshowHideDevices ref={sectionRefs['Trigger Phrases']}>
                <h2>Trigger Phrases</h2>
                <div className="trigger-info">
                  <FontAwesomeIcon icon={faWalkieTalkie} className="trigger-icon" />
                  <span>Trigger an escalation and start recording when a user says a particular phrase.</span>
                </div>
                <div className="triggers-list">
                  {triggers.map((trigger, index) => (
                    <div key={index} className="trigger-row">
                      <div className="trigger-input-container">
                        <div className="trigger-input-wrapper">
                          <Autocomplete
                            freeSolo
                            options={['Code Red', 'Code Pink', 'Code Blue', 'Code Green', 'Code Yellow']}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                error={!!errors[index]?.condition}
                                helperText={errors[index]?.condition}
                                className="trigger-input"
                                value={editingTriggerIndex === index ? editingTrigger : trigger.condition || ''}
                                onChange={(e) => {
                                  if (editingTriggerIndex === index) {
                                    setEditingTrigger(e.target.value);
                                  }
                                }}
                                onInputChange={(event, newValue) => {
                                  if (editingTriggerIndex === index) {
                                    setEditingTrigger(newValue || '');
                                  }
                                }}
                                onFocus={() => handleStartEdit(index, trigger.condition)}
                              />
                            )}
                            value={editingTriggerIndex === index ? editingTrigger : trigger.condition || ''}
                            onChange={(event, newValue) => {
                              if (editingTriggerIndex === index) {
                                setEditingTrigger(newValue || '');
                              }
                            }}
                          />
                          {editingTriggerIndex !== index && (
                            <IconButton onClick={() => handleRemoveTrigger(index)}>
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          )}
                        </div>
                        {editingTriggerIndex === index && (
                          <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
                            <Button
                              variant="contained"
                              onClick={() => handleSaveTriggerPhrase(index)}
                              startIcon={<CheckIcon />}
                              disabled={!editingTrigger.trim()}
                              sx={{
                                backgroundColor: '#cb2d3e',
                                '&:hover': {
                                  backgroundColor: '#b52d2e',
                                },
                              }}
                            >
                              Save
                            </Button>
                            <Button variant="outlined" onClick={handleCancelEdit} startIcon={<CloseIcon />}>
                              Cancel
                            </Button>
                          </Box>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="add-trigger-wrapper">
                  <Button
                    variant="contained"
                    onClick={handleAddTrigger}
                    disabled={editingTriggerIndex !== null || isAddingTrigger}
                    startIcon={<AddIcon />}
                    className="add-trigger-button"
                  >
                    Add Trigger Phrase
                  </Button>
                  <span className="trigger-warning">Avoid using common words as trigger phrases.</span>
                </div>
                <div className="stop-phrase-section">
                  <div className="trigger-info">
                    <FontAwesomeIcon icon={faHandPaper} className="trigger-icon" />
                    <span>Say the stop phrase to stop a recording that was started with a trigger phrase.</span>
                  </div>
                  <div className="stop-phrase-input">
                    <Autocomplete
                      freeSolo
                      options={['Stop Recording', 'End Recording', 'Code Purple']}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          fullWidth
                          error={!!stopPhraseError}
                          helperText={stopPhraseError || 'This phrase will stop the recording when spoken.'}
                        />
                      )}
                      value={editingStopPhrase ? editingStopPhraseValue : stopPhrase}
                      onChange={(event, newValue) => {
                        if (editingStopPhrase) {
                          setEditingStopPhraseValue(newValue || '');
                        }
                      }}
                      onInputChange={(event, newValue) => {
                        if (editingStopPhrase) {
                          setEditingStopPhraseValue(newValue || '');
                        }
                      }}
                      onFocus={handleStartEditStopPhrase}
                    />
                    {editingStopPhrase && (
                      <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
                        <Button
                          variant="contained"
                          onClick={handleSaveStopPhrase}
                          startIcon={<CheckIcon />}
                          disabled={!editingStopPhraseValue.trim()}
                          sx={{
                            backgroundColor: '#cb2d3e',
                            '&:hover': {
                              backgroundColor: '#b52d2e',
                            },
                          }}
                        >
                          Save
                        </Button>
                        <Button variant="outlined" onClick={handleCancelEditStopPhrase} startIcon={<CloseIcon />}>
                          Cancel
                        </Button>
                      </Box>
                    )}
                  </div>
                </div>
              </div>
              <br></br>
              {reportsSection}
            </>
          )}

          {featureMode === 'dev' && (
            <div className="configuration-section">
              <h2>Advanced Settings</h2>
              <Link to="/device-management" className="device-management-link">
                Manage Devices
              </Link>
            </div>
          )}
        </div>

        <Modal open={consentModalOpen} onClose={handleConsentCancel}>
          <Box className="modal-container">
            <h2>Consent Required</h2>
            <p>By providing your phone number, you consent to receive text messages for notifications. Do you agree?</p>
            <div className="modal-buttons">
              <Button onClick={handleConsentCancel} className="cancel-button">
                Cancel
              </Button>
              <Button onClick={handleConsentAgree} className="confirm-button">
                Agree
              </Button>
            </div>
          </Box>
        </Modal>
      </div>

      {/* Add this dialog at the end of the wrapper, before the closing div */}
      <Dialog open={deleteConfirmOpen} onClose={() => setDeleteConfirmOpen(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete the account for {accountToDelete?.assignedTo}? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {editPersonnelDialog}
      {/* Add the dialog */}
      {configureAllDevicesDialog}
      {configureDeviceDialog}

      {/* Add this Snackbar component right before the closing div */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </div>
  );
};

export default Configuration;
